import React from "react"

import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import Students from "../components/Images/studentList"
import ImageGallery from "../components/imageGallery"
import NoImage from "../components/noImage"

const IndexStyles = styled.div`
  text-align: center;

  h3 {
    background: #fff;
    padding: 10px;
    width: 80%;
    margin: 10px auto;
    border-radius: 5px;
  }
`

const IndexPage = props => {
  const images = props.data.allFile.edges

  return (
    <Layout>
      <SEO title="Class of 2020" />
      <IndexStyles>
        <h3>
          <q>Trust yourself. You know more than you think you do.</q>
          <br /> <br />
          -Benjamin Spock
        </h3>
        <Students />
        <br />
        <ImageGallery images={images} />
        <NoImage />
      </IndexStyles>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "classImages" } }) {
      edges {
        node {
          childImageSharp {
            id
            original {
              src
            }
          }
        }
        previous {
          id
          childImageSharp {
            id
            original {
              src
            }
          }
        }
        next {
          id
          childImageSharp {
            id
            original {
              src
            }
          }
        }
      }
    }
  }
`
