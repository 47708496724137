import React from "react"
import styled from "styled-components"
import GlobalVariables from "./globalStyles"

const Contactlayout = styled.div`
  width: 100%;
  background: var(--white);
  padding: 40px;

  h2 {
    color: var(--red);
  }
`

const noImage = () => {
  return (
    <Contactlayout>
      <GlobalVariables />
      <h2>Dont see your image? </h2>
      <p>Send a profile picture, with your name and a quote.</p>
      <button className="Button">
        <a href="mailto:karlayarels@yahoo.com?subject=PierceNursing.com Image">
          Send Image
        </a>
      </button>
    </Contactlayout>
  )
}

export default noImage
