import andrewValencia from "./images/Andrew Valencia.png"
import BiancaL from "./images/Bianca Lombardo.png"
import ChelsiP from "./images/Chelsi Portman.png"
import ChristineS from "./images/Christine Schwarcz.png"
import CynthiaR from "./images/Cynthia Rodriguez.png"
import KarlaR from "./images/Karla Ruvalcaba.png"
import SarahiS from "./images/Sarahi Santana.jpg"
import Male from "./images/male.png"
import Female from "./images/female.png"

const students = [
  {
    name: "Gonzalo Aguirre ",
    other: {
      src: Male,
      quote: null,
      // specialty: "rn",
      id: "1",
    },
  },
  {
    name: "Aryana Banaee",
    other: {
      src: Female,
      quote: null,
      // specialty: "rn",
      id: "2",
    },
  },
  {
    name: "David Castaneda",
    other: {
      src: Male,
      quote: "",
      // specialty: "rn",
      id: "3",
    },
  },
  {
    name: "Gladys Cobian",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "4",
    },
  },
  {
    name: "Brian Cortave",
    other: {
      src: Male,
      quote: "",
      // specialty: "rn",
      id: "5",
    },
  },
  {
    name: "Charisse Galano",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "6",
    },
  },
  {
    name: "Christian Gutierrez",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "7",
    },
  },
  {
    name: "Nicole De Guzman",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "8",
    },
  },
  {
    name: "Amna Khan",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "9",
    },
  },
  {
    name: "Megan Lieberman",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "10",
    },
  },
  {
    name: "Bianca Lombardo",
    other: {
      src: BiancaL,
      quote: `"Build your garden and let the butterflies come to you"`,
      // specialty: "rn",
      id: "11",
    },
  },
  {
    name: "Stephanie Martinez",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "12",
    },
  },
  {
    name: "Nicole Mccampbell",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "13",
    },
  },
  {
    name: "Ashley Owen",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "14",
    },
  },
  {
    name: "Natalie Piotrowski",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "15",
    },
  },
  {
    name: "Chelsi Portman",
    other: {
      src: ChelsiP,
      quote: `"When people show you who they are, believe them the first time."`,
      // specialty: "rn",
      id: "16",
    },
  },
  {
    name: "Maria Rebueno",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "17",
    },
  },
  {
    name: "Cynthia Rodriguez",
    other: {
      src: CynthiaR,
      quote: `"Trust the wait. Embrace the uncertainty. Enjoy the beauty of becoming."`,
      // specialty: "rn",
      id: "18",
    },
  },
  {
    name: "Karla Ruvalcaba",
    other: {
      src: KarlaR,
      quote: `"The only BS I need in my life is a Bachelors of Science in Nursing.”`,
      // specialty: "rn",
      id: "19",
    },
  },
  {
    name: "Sarahi Santana Osorio ",
    other: {
      src: SarahiS,
      quote: `"Success is not final. Failure is not fatal. It is the courage to continue that counts."`,
      // specialty: "rn",
      id: "20",
    },
  },
  {
    name: "Stacey Schultheis",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "21",
    },
  },
  {
    name: "Christine Schwarcz",
    other: {
      src: ChristineS,
      quote: `"Take every chance, drop every fear"`,
      // specialty: "rn",
      id: "22",
    },
  },
  {
    name: "Rachel Sneed",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "23",
    },
  },
  {
    name: "Thu-Ha Tran",
    other: {
      src: Female,
      quote: "",
      // specialty: "rn",
      id: "24",
    },
  },
  {
    name: "Andrew Valencia",
    other: {
      src: andrewValencia,
      quote: `"If you look for the light, you can often find it, but if you look for the dark, that is all you will ever see."`,
      // specialty: "rn",
      id: "25",
    },
  },
]

export default students
