import React, { useState } from "react"
import styled from "styled-components"
import ImageModal from "../components/imageModal"

const ImageStyle = styled.div`
  box-sizing: border-box;
  margin: 0 auto;

  img {
    width: 350px;
    height: 350px;
    object-fit: scale-down;
    background: white;
  }
`

const GalleryContainer = styled.div`
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
`

// const showModal = props => {
//   onClick()
// }

const imageGallery = props => {
  //   const [active, setActive] = useState(false)
  //   console.log(active)

  const imageList = props.images
  const imageGallery = imageList.map(singleImage => {
    // console.log(singleImage)
    let imgSrc

    return (
      <ImageStyle>
        <img
          src={singleImage.node.childImageSharp.original.src}
          alt="Pierce Class or 2020"
          onClick={() => {
            return <ImageModal />
          }}
        />
      </ImageStyle>
    )
  })

  return (
    <div>
      <h1>Images</h1>
      <GalleryContainer>{imageGallery}</GalleryContainer>
    </div>
  )
}

export default imageGallery

// console.log(singleImage.node.childImageSharp.id)
