import React from "react"
import styled from "styled-components"
import StudentFile from "../../students"

const GalleryStyle = styled.div`
  width: 80%;
  margin: 0 auto;

  h6 {
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    font-style: italic;
    margin: 10px;
  }

  h2 {
    text-align: center;
    margin: 10px 0;
  }
`

const studentList = props => {
  const StudentImages = StudentFile.map(Student => {
    return (
      <div className="flex flex-col items-center justify-center bg-white p-1 shadow rounded-lg">
        <div
          key={Student.other.id}
          className="inline-flex shadow-lg border border-gray-200 overflow-hidden h-40 w-40"
        >
          <img
            src={Student.other.src}
            alt={Student.name}
            className="h-full w-full"
          />
        </div>
        <h2 className="mt-4 font-bold text-xl">{Student.name}</h2>
        <h6 className="mt-2 text-sm font-medium">
          <p>{Student.other.quote}</p>
        </h6>
      </div>
    )
  })

  return (
    <GalleryStyle className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
      {StudentImages}
    </GalleryStyle>
  )
}

export default studentList
