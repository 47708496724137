import React from "react"
import styled from "styled-components"

const ImageModalStyle = styled.div`
  width: 80%;
  height: 80%;
  background: red;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`

const imageModal = props => {
  return (
    <ImageModalStyle>
      <h1>Hello there</h1>
      {console.log("Whats up!")}
    </ImageModalStyle>
  )
}

export default imageModal
